.Stop {
    position: absolute;
}

.Stop .marker {
    width:  0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: #808080;
}

.Stop .marker:hover {
    background-color: black;
}

.Stop .marker:hover + .StopLabel {
    opacity: 1;
    z-index: 10;
    border:  1px solid black;
}
