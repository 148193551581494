.VehicleLabel {
    font-family: monospace;
    font-size:   1em;
    text-align:  right;
    white-space: nowrap;

    position: absolute;
    z-index:  10;
    bottom:   100%;
    right:    100%;
    padding:  0.1em;

    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0.7);
}