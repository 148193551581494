
.App {
    position: relative;
    height:   100vh;
    background-color: rgba(0, 0, 0, 0.025);
}

.controls {
    position: fixed;
    top:   0;
    left:  0;
    right: 0;
}
