.Vehicle {
    position: absolute;
}

.Vehicle .marker {
    width:  0; 
    height: 0; 
    border-left:   0.7em solid transparent;
    border-right:  0.7em solid transparent;
    border-bottom: 0.7em solid #5c5cd6;
}
